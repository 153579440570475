import { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useActivityEffectPricing() {
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState({});

    const abortControllerRef = useRef(null);

    const fetchEffectPricing = useCallback(async ({ activityID = '', segmentID = '', variantID = '' }) => {
        abortControllerRef.current = new AbortController();
        setFetching(true);
        const req = {
            activityID: activityID,
            segmentID: segmentID,
            variantID: variantID,
        };
        try {
            const response = await fetch(`${config.apiURL}/activity/effect-pricing`, {
                method: 'POST',
                body: JSON.stringify(req),
                headers: { Authorization: `Bearer ${GetToken()}` },
                signal: abortControllerRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred while fetch an effective pricing');
            }
            const data = await response.json();
            // console.log('Effective pricing fetched:', data);
            setData(data);
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetching an effective pricing:', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { fetching, data, fetchEffectPricing, abort };
}

useActivityEffectPricing.propTypes = {};

export default useActivityEffectPricing;
