import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Precentage } from '../utils/utils';
import Incentive from './Incentive';
import { Modal } from 'antd';

function CommissionPrint({ commission = { ratio: 0, constant: 0 }, defaultRate = 0 }) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <div onClick={() => setOpen(true)} className="pointer hover">
                {`佣金`}
                {!!commission.ratio ? `${Precentage(commission.ratio)}%` : `${Precentage(defaultRate)}%`}
                {!!commission.constant && `+A$${commission.constant}`}
                <sup>*</sup>
            </div>
            <Modal title="佣金" open={open} onCancel={() => setOpen(false)} footer={null} width={'auto'} centered>
                <div style={{ width: '100%' }}>
                    <Incentive />
                </div>
            </Modal>
        </>
    );
}

CommissionPrint.propTypes = {
    defaultRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    commission: PropTypes.PropTypes.shape({
        ratio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        constant: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
};

export default CommissionPrint;
