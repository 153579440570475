import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useState } from 'react';
import Activities from '@components/activity/Activities';
import styles from '@styles/DemoPage.module.css';
import TopHeaderBg from '@assets/activity_top_bg_2.png';

function DemoPage({ title, fixedSelector }) {
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    // Redux Memoized Selectors
    const selectorData = (state) => state.activities.data;
    const filterHiden = createSelector(selectorData, (items) => items?.filter((item) => item.hidden == false));
    const activities = useSelector((state) => {
        return filterHiden(state);
    });
    const fetching = useSelector((state) => state.activities.fetching);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        filterActivities();
    }, [fetching, fixedSelector]);

    const filterActivities = () => {
        setLoaded(false);
        const params = new URLSearchParams(searchParams);
        const sort = params.get('sort');
        let newData = [...activities];
        // Fixed selector
        newData = activities.filter((item) => {
            if (item.selectors?.includes(fixedSelector)) {
                return true;
            }
        });
        // Selector sorting
        newData?.sort((a, b) => {
            const sortA = a.selectorItems.find((item) => item.name == fixedSelector).sort;
            const sortB = b.selectorItems.find((item) => item.name == fixedSelector).sort;
            return sortA - sortB;
        });

        if (sort) {
            const calculatePrice = (pricing) => {
                if (pricing.bundlePriced) {
                    return pricing.bundlePrice;
                }
                return pricing.adultPrice;
            };
            switch (sort) {
                case 'price-asc':
                    newData?.sort((a, b) => {
                        return calculatePrice(a.pricing) - calculatePrice(b.pricing);
                    });
                    break;
                case 'price-desc':
                    newData?.sort((a, b) => {
                        return calculatePrice(b.pricing) - calculatePrice(a.pricing);
                    });
                    break;
                default:
                    break;
            }
        }
        setData(newData);
        setLoaded(true);
    };

    return (
        <div className={styles.container}>
            <section className={styles.top_bg_container}>
                <img src={TopHeaderBg} />
                <div className={styles.works_container}>
                    <div className={styles.paragraph}>{title}</div>
                </div>
            </section>
            <section className={styles.content_container}>
                <Activities activities={data} loaded={loaded} callback={() => {}} priceSorting={false} />
            </section>
        </div>
    );
}

DemoPage.propTypes = {
    title: PropTypes.string,
    fixedSelector: PropTypes.string.isRequired,
};

export default DemoPage;
