import React from 'react';
import * as config from '@config';
import styles from '@styles/TicketPage.module.css';
import SelectorsForm from '@components/SelectorsForm';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ActivityHotTagOptions, SelectorParser, Selector } from '@utils/constants';
import TopHeaderBg from '@assets/activity_top_bg.png';

function TicketPage() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleSearch = () => {
        const params = new URLSearchParams(searchParams);
        navigate(`search?${params.toString()}`);
    };

    return (
        <div className={styles.container}>
            <section className={styles.top_bg_container}>
                <img src={TopHeaderBg} />
                <div className={styles.works_container}>
                    <div className={styles.paragraph}>活动体验</div>
                </div>
            </section>
            <section className={styles.content_container}>
                <div className={styles.form_container}>
                    <SelectorsForm tagOptions={ActivityHotTagOptions} handleSubmit={handleSearch} />
                </div>

                <div className={styles.title}>热门城市</div>
                <div className={styles.highlights_container}>
                    {[
                        { selector: Selector.Sydney },
                        { selector: Selector.Melbourne },
                        { selector: Selector.Brisbane },
                        { selector: Selector.GoldCoast },
                        { selector: Selector.Perth },
                        { selector: Selector.Cairns },
                        { selector: Selector.Adelaide },
                        { selector: Selector.Canberra },
                    ].map((item, index) => {
                        return (
                            <div key={index} className={styles.highlight_container}>
                                <Link to={`search?selector=${item.selector}`}>
                                    <div
                                        className={styles.image_container}
                                        style={{
                                            backgroundImage: `url('${config.baseURL}/images/place_${item.selector}.webp')`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                        }}
                                    ></div>
                                </Link>
                                <div className={styles.caption}>{SelectorParser(item.selector)}</div>
                            </div>
                        );
                    })}
                </div>
            </section>
        </div>
    );
}

export default TicketPage;
