import React, { useState, useEffect, useLayoutEffect } from 'react';
import styles from '@styles/SearchPage.module.css';
import SelectorsForm from '@components/SelectorsForm';
import { Loader } from 'semantic-ui-react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Selector } from '@utils/constants';
import Activities from '@components/activity/Activities';
import ActivitiesFilter, { K_Kinds, K_Durations, K_Recommendations } from '@components/activity/ActivitiesFilter';
import BackBar from '@components/BackBar';
import { createSelector } from '@reduxjs/toolkit';
import { RestoreScrollPosition, StoreScrollPosition } from '@utils/scrollPosition';

export function SearchPage() {
    const location = useLocation();
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    // Redux Memoized Selectors
    const selectorData = (state) => state.activities.data;
    const filterHiden = createSelector(selectorData, (items) => items?.filter((item) => item.hidden == false));
    const activities = useSelector((state) => {
        return filterHiden(state);
    });
    const fetching = useSelector((state) => state.activities.fetching);
    const [searchParams, setSearchParams] = useSearchParams();

    useLayoutEffect(() => {
        return () => {
            StoreScrollPosition(location.pathname + location.search);
        };
    }, []);

    useEffect(() => {
        RestoreScrollPosition(location.pathname + location.search);
    }, [data]);

    useEffect(() => {
        filterActivities();
    }, [searchParams, fetching]);

    const filterActivities = () => {
        setLoaded(false);
        const params = new URLSearchParams(searchParams);
        let selector = params.get('selector') ?? '';
        let text = params.get('text') ?? '';
        if (selector == 'all') {
            selector = '';
        }
        const sort = params.get('sort');
        let newData = [...activities];
        // city
        if (selector) {
            newData = newData.filter((item) => {
                if (item.selectors?.includes(selector)) {
                    return true;
                }
            });
            // Selector sorting
            newData?.sort((a, b) => {
                const sortA = a.selectorItems.find((item) => item.name == selector).sort;
                const sortB = b.selectorItems.find((item) => item.name == selector).sort;
                return sortA - sortB;
            });
        }
        // filter kinds
        let kinds = params.getAll(K_Kinds) ?? [];
        if (kinds.length != 0) {
            if (kinds.length == 1 && kinds[0] == 'ticket') {
                newData = newData.filter((item) => {
                    if (
                        !item.selectors?.includes(Selector.Cars) &&
                        !item.selectors?.includes(Selector.Cruises) &&
                        !item.selectors?.includes(Selector.Team) &&
                        !item.selectors?.includes(Selector.Package) &&
                        !item.selectors?.includes(Selector.OtherExperience)
                    ) {
                        return true;
                    }
                });
            } else {
                newData = newData.filter((item) => {
                    for (let filter of kinds) {
                        if (item.selectors?.includes(filter)) {
                            return true;
                        }
                    }
                });
            }
        }
        // filter recommendations
        let recommendations = params.getAll(K_Recommendations) ?? [];
        if (recommendations.length != 0) {
            if (recommendations.includes('family')) {
                newData = newData.filter((item) => {
                    if (!!item?.pricing?.hasFamilyPlan) {
                        return true;
                    }
                });
            }
        }
        // filter duration
        let durations = params.getAll(K_Durations) ?? [];
        if (durations.length != 0) {
            newData = newData.filter((item) => {
                if (durations.includes(item?.duration?.toString())) {
                    return true;
                }
            });
        }

        if (text) {
            newData = newData.filter((item) => {
                if (
                    item.name?.toLowerCase().includes(text.toLowerCase()) ||
                    item.nameEN?.toLowerCase().includes(text.toLowerCase())
                ) {
                    return true;
                }
            });
        }
        if (sort) {
            const calculatePrice = (pricing) => {
                if (pricing.bundlePriced) {
                    return pricing.bundlePrice;
                }
                return pricing.adultPrice;
            };
            switch (sort) {
                case 'price-asc':
                    newData?.sort((a, b) => {
                        return calculatePrice(a.pricing) - calculatePrice(b.pricing);
                    });
                    break;
                case 'price-desc':
                    newData?.sort((a, b) => {
                        return calculatePrice(b.pricing) - calculatePrice(a.pricing);
                    });
                    break;
                default:
                    break;
            }
        }
        setData(newData);
        setLoaded(true);
    };

    return (
        <div className={styles.container}>
            <BackBar />
            <div className={styles.form_container}>
                <SelectorsForm />
            </div>
            <div className={styles.table_container}>
                <Loader active={fetching} size="large"></Loader>
                <Activities activities={data} loaded={loaded} filterElement={<ActivitiesFilter />} />
            </div>
        </div>
    );
}
